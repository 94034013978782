import React, { Component } from 'react';
import styled from 'styled-components';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Modal from '../../components/Modal/Modal';
import Axios from 'axios';
import ClipLoader from 'react-spinners/ClipLoader';
import PulseLoader from 'react-spinners/PulseLoader';
import InputMask from 'react-input-mask';

const Label = styled.label`
  font-size: 0.7rem;
  font-weight: 500;
`;

const SmallLabel = styled.label`
  font-size: 1rem;
  color: #a94442;
  margin-left: 25px;
`;

const RadioInput = styled.input`
  margin-left: 0 !important;
`;

const StyledP = styled.p`
  font-size: 0.7rem;
  font-weight: bold;
  margin-top: 1rem;
`;

const StyledDiv = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;

const StyledCol = styled.div`
  padding: 8px;
  @media (max-width: 768px) {
    display: none;
  }
`;

const Styledh4 = styled.h4`
  padding: 9px;
  margin: 0px 0px 8px;
  display: flex;
  margin-top: 22px;
  margin-left: 18px;
`;

const DynamicLabel = styled.label`
  display: none;
  @media (max-width: 768px) {
    display: grid;
  }
`;

const StyledButton = styled.button`
  display: initial !important;
  @media (max-width: 768px) {
    display: none !important;
  }
`;

const StyledButtonForColSm = styled.button`
  display: none !important;
  @media (max-width: 768px) {
    margin-top: 1rem;
    display: initial !important;
  }
`;

const StyledLabel = styled.label`
  font-weight: bold;
  font-size: 0.7rem;
  margin-right: 1rem;
`;

const ButtonAddRemoveAddress = styled.button`
  font-size: 0.7rem !important;
  white-space: normal !important;
`;

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  //margin: `0 0 ${grid}px 0`,
  display: 'flex',
  border: '1px solid #ddd',

  // change background colour if dragging
  background: isDragging ? 'lightgreen' : '#e9e9e9',

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'lightblue' : 'transparent',
  width: '100%',
  padding: '8px 0 0 0px',
});

class Step2 extends Component {
  constructor(props) {
    super(props);

    const step1_newValue = this.props.step1_newValue;

    this.state = {
      addDifferentAddress: false,
      showEnderecoDivulga: false,
      showModal: false,
      modalMessage: '',
      modalTitle: '',
      redirectToHome: false,
      redirectToStep: false,
      stepToRedirect: null,
      resource: {
        aceite: true,
        respCert: '',
        emailRespCert: '',
        telRespCert: '',
        nomeFantasia: '',
        site: '',
        emailDivulgacao: '',
        telefoneDivulgacao: '',
        cep: step1_newValue.cep,
        logradouro: step1_newValue.logradouro,
        numero: step1_newValue.numero,
        complemento: step1_newValue.complemento,
        cidade: step1_newValue.cidade,
        uf: step1_newValue.uf,
        enderecosIguais: 1,
        socios_rowOrder: '1',
        socios_nome_1: '',
        socios_cpf_1: '',
      },
      enderecoDivulga: {
        cep1: '',
        logradouro1: '',
        numero1: '',
        complemento1: '',
        cidade1: '',
        uf1: '',
      },
      items: [
        {
          id: 1,
        },
      ],
      loading: false,
      fileLoading: false,
    };

    this.isValidated = this.isValidated.bind(this);

    // Remove item on Click
    this.removeItem = index => {
      const { items, resource } = this.state;
      items.splice(index, 1);

      let string = '';
      items.forEach((item, index) => {
        if (index + 1 === items.length) {
          string += `${item.id}`;
        } else string += `${item.id},`;
      });
      this.setState({
        items: items,
        resource: {
          ...resource,
          socios_rowOrder: string,
        },
      });
      //this.setState({ items: items });
    };

    // Add form on Click
    this.addItem = event => {
      event.preventDefault();
      const { items, resource } = this.state;
      const arrayContains = items.filter(item => item.id === items.length + 1);
      if (arrayContains.length === 0) {
        items.push({
          id: items.length + 1,
        });
      } else {
        const greaterNumberFromArray = Math.max(items.map(item => item.id));
        items.push({
          id: greaterNumberFromArray + 1,
        });
      }
      let string = '';
      items.forEach((item, index) => {
        if (index + 1 === items.length) {
          string += `${item.id}`;
        } else string += `${item.id},`;
      });
      this.setState({
        items: items,
        resource: {
          ...resource,
          socios_rowOrder: string,
        },
      });
    };

    this.handleChange = (prop, value) => {
      this.setState(({ resource }) => ({
        resource: {
          ...resource,
          [prop]: value,
        },
      }));
    };

    this.handleChangeEnderecoDivulga = (prop, value) => {
      this.setState(({ enderecoDivulga }) => ({
        enderecoDivulga: {
          ...enderecoDivulga,
          [prop]: value,
        },
      }));
    };

    this.saveFile = async (event, cod) => {
      this.setState({ fileLoading: true });
      let formData = new FormData();
      formData.append('codProcesso', this.props.codProcesso);
      formData.append(`avcb_${cod}`, event.target.files[0]);
      console.log(event.target.files[0]);

      const config = {
        headers: {
          'Content-Type':
            'amultipart/form-data; boundary=----WebKitFormBoundaryhPJNXKmln77JNptS',
          Accept: '*/*',
        },
      };

      // eslint-disable-next-line no-undef
      const response = await Axios.post(
        `${process.env.GATSBY_API_URL}Processo/enviarArquivo/${cod}/avcb/`,
        formData,
        config
      );

      if (response) this.setState({ fileLoading: false });

      return response;
    };

    this.checkProperties = obj => {
      let isValid = true;
      Object.keys(obj).forEach(item => {
        if (obj[item] === '') {
          isValid = false;
        }
      });

      return isValid;
    };

    this.handleCheckClick = () => {
      const { resource } = this.state;
      this.setState({
        resource: {
          ...resource,
          aceite: !resource.aceite,
        },
      });
    };

    this.verificaDocumentoProcesso = async () => {
      const config = {
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
      };

      // eslint-disable-next-line no-undef
      const response = await Axios.post(
        `${process.env.GATSBY_API_URL}Processo/verificaLinkDocumentoJSON/${
          this.props.codProcesso
        }`,
        config
      );

      if (response.data) {
        return response.data.total;
      }

      return false;
    };

    this.recordAddress = async (equalAddress, codEmpresa, codTipoEndereco) => {
      const { resource, enderecoDivulga } = this.state;

      let formData = new FormData();
      formData.append('codEmpresa', codEmpresa);
      formData.append('codTipoEndereco', codTipoEndereco);

      if (!equalAddress) {
        if (codTipoEndereco === 1) {
          formData.append('cep', resource.cep);
          formData.append('logradouro', resource.logradouro);
          formData.append('numero', resource.numero);
          formData.append('complemento', resource.complemento);
          formData.append('cidade', resource.cidade);
          formData.append('uf', resource.uf);
        }
        if (codTipoEndereco === 2) {
          formData.append('cep1', enderecoDivulga.cep1);
          formData.append('logradouro1', enderecoDivulga.logradouro1);
          formData.append('numero1', enderecoDivulga.numero1);
          formData.append('complemento1', enderecoDivulga.complemento1);
          formData.append('cidade1', enderecoDivulga.cidade1);
          formData.append('uf1', enderecoDivulga.uf1);
        }
      }

      if (equalAddress) {
        if (codTipoEndereco === 1) {
          formData.append('cep', resource.cep);
          formData.append('logradouro', resource.logradouro);
          formData.append('numero', resource.numero);
          formData.append('complemento', resource.complemento);
          formData.append('cidade', resource.cidade);
          formData.append('uf', resource.uf);
        }
        if (codTipoEndereco === 2) {
          formData.append('cep1', resource.cep);
          formData.append('logradouro1', resource.logradouro);
          formData.append('numero1', resource.numero);
          formData.append('complemento1', resource.complemento);
          formData.append('cidade1', resource.cidade);
          formData.append('uf1', resource.uf);
        }
      }

      const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Accept: '*/*',
        },
      };

      // eslint-disable-next-line no-undef
      const response = await Axios.post(
        `${process.env.GATSBY_API_URL}Empresa/gravarEndereco/`,
        formData,
        config
      );

      if (response.data) {
        return response.data;
      }

      return false;
    };

    this.updateCompany = async () => {
      const { resource } = this.state;
      let formData = new FormData();
      formData.append('codEmpresa', this.props.codEmpresa);
      formData.append('codProcesso', this.props.codProcesso);
      for (let key in resource) {
        formData.append(key, resource[key]);
      }
      const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Accept: '*/*',
        },
      };
      //eslint-disable-next-line no-undef
      const response = await Axios.post(
        `${process.env.GATSBY_API_URL}Empresa/processaComplementares/`,
        formData,
        config
      );

      return response;
    };

    this.beforeMaskedValueChange = (newState, oldState, userInput) => {
      let { value } = newState;
      var selection = newState.selection;

      if (selection && selection.end === 14) {
        value = value.replace('-', '');
        value = value.slice(0, 9) + '-' + value.slice(9, 13);
      }
      return {
        value,
        selection,
      };
    };

    this.validationCheck = () => {
      const userInput = this._grabUserInputToValidate(); // grab user entered vals

      const validateNewInput = this._validateData(userInput); // run the new input against the validator
      console.log(validateNewInput);

      this.setState(
        Object.assign(
          userInput,
          validateNewInput,
          this._validationErrors(validateNewInput)
        )
      );
    };

    this._grabUserInputToValidate = () => {
      const { resource } = this.state;
      return {
        emailRespCert: resource.emailRespCert,
        emailDivulgacao: resource.emailDivulgacao,
      };
    };

    this._validateData = data => {
      return {
        emailRespCertVal: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
          data.emailRespCert
        ), // required: regex w3c uses in html5
        emailDivulgacaoVal: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
          data.emailDivulgacao
        ), // required: regex w3c uses in html5
      };
    };

    this._validationErrors = val => {
      const errMsgs = {
        emailRespCertValMsg: val.emailRespCertVal
          ? ''
          : 'Um E-mail válido é Obrigatório',
        emailDivulgacaoValMsg: val.emailDivulgacaoVal
          ? ''
          : 'Um E-mail válido é Obrigatório',
      };
      return errMsgs;
    };

    this.onDragEnd = this.onDragEnd.bind(this);
  }

  async isValidated() {
    const { resource, enderecoDivulga } = this.state;
    const { step1_newValue, codProcesso } = this.props;
    const userInput = this._grabUserInputToValidate(); // grab user entered vals
    const validateNewInput = this._validateData(userInput); // run the new input against the validator
    console.log('validateNewInput', validateNewInput);

    this.setState({ loading: true });
    // save on redux store
    //this.props.updateRes2(resource);

    const requiredFields = {
      respCert: resource.respCert,
      emailRespCert: resource.emailRespCert,
      telRespCert: resource.telRespCert,
      nomeFantasia: resource.nomeFantasia,
      site: resource.site,
      emailDivulgacao: resource.emailDivulgacao,
      telefoneDivulgacao: resource.telefoneDivulgacao,
    };

    //checa se todos os dados estão preenchidos
    if (!this.checkProperties(requiredFields)) {
      this.setState({
        showModal: true,
        modalTitle: 'Atenção',
        modalMessage: 'Você precisa preencher todos os campos com o *.',
      });
      this.setState({ loading: false });
      return false;
    }

    // Checa se os emails estão validos
    if (
      !validateNewInput.emailDivulgacaoVal ||
      !validateNewInput.emailRespCertVal
    ) {
      return false;
    }
    // Verifica se existe algum arquivo já anexado antes de prosseguir caso buffet ou espaço

    if (
      step1_newValue.segmento.value === '6' ||
      step1_newValue.segmento.value === '68'
    ) {
      const processo = codProcesso;

      const total = await this.verificaDocumentoProcesso(processo);

      if (total === '0') {
        this.setState({
          showModal: true,
          modalTitle: 'Atenção',
          modalMessage:
            'Você precisa anexar os documentos solicitados para continuar.',
        });
        this.setState({ loading: false });
        return false;
      }
    }

    /**
     * Verifica se o aceite está marcado para prosseguir
     */
    if (resource.aceite) {
      /**
       * Se o aceite não está marcado, exibe mensagem de erro
       */
      this.setState({
        showModal: true,
        modalTitle: 'Atenção',
        modalMessage:
          'Você precisa aceitar as condições da taxa de análise para prosseguir.',
      });
      this.setState({ loading: false });
      return false;
    }

    /**
     * Verifica se  existe pelo menos 1 socio
     */
    if (resource.socios_cpf_1 === '' && resource.socios_nome_1 === '') {
      this.setState({
        showModal: true,
        modalTitle: 'Atenção',
        modalMessage: 'Você precisa Adicionar pelo menos 1 sócio.',
      });
      this.setState({ loading: false });
      return false;
    }
    /**
     * Verifica se os endereços são diferentes e, caso sejam,
     * verifica se o formulário de endereço de divulgação está
     * preenchido corretamente
     */
    if (resource.enderecosIguais === 0) {
      const endDivulga = {
        cep1: enderecoDivulga.cep1,
        logradouro1: enderecoDivulga.logradouro1,
        numero1: enderecoDivulga.numero1,
        cidade: enderecoDivulga.cidade1,
        uf1: enderecoDivulga.uf1,
      };
      if (!this.checkProperties(endDivulga)) {
        this.setState({
          showModal: true,
          modalTitle: 'Atenção',
          modalMessage:
            'Preencha todos os campos obrigatórios para continuar (marcados com *). Se incluiu um endereço diferente de digulgação verifique se todos os campos estão preenchidos.',
        });
        this.setState({ loading: false });
        return false;
      }
    }

    /**
     * Grava endereços do cliente
     */
    const recordedAddress = await this.recordAddress(
      true,
      this.props.codEmpresa,
      1
    );

    if (recordedAddress !== 1) {
      this.setState({
        showModal: true,
        modalTitle: 'Atenção',
        modalMessage: 'Erro ao tentar gravar. Tente novamente mais tarde.',
      });
      this.setState({ loading: false });
      return false;
    }

    if (resource.enderecosIguais === 1) {
      await this.recordAddress(true, this.props.codEmpresa, 2);
    } else {
      await this.recordAddress(false, this.props.codEmpresa, 2);
    }

    /**
     * Se os dados forem todos preenchidos, atualiza o cadastro da
     * empresa com os dados inseridos
     */

    const updateCompany = await this.updateCompany();
    if (updateCompany.data !== 1) {
      this.setState({
        showModal: true,
        modalTitle: 'Atenção',
        modalMessage: 'Erro ao gravar. Tente novamente mais tarde',
      });
      this.setState({ loading: false });
      return false;
    }

    return true;
  }

  onDragEnd(result) {
    const { resource } = this.state;
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      this.state.items,
      result.source.index,
      result.destination.index
    );

    this.setState(
      {
        items,
      },
      () => {
        let string = '';
        items.forEach((item, index) => {
          if (index + 1 === items.length) {
            string += `${item.id}`;
          } else string += `${item.id},`;
        });
        this.setState({
          items: items,
          resource: {
            ...resource,
            socios_rowOrder: string,
          },
        });
      }
    );
  }

  componentDidMount() {}

  render() {
    const {
      resource,
      items,
      addDifferentAddress,
      showEnderecoDivulga,
      showModal,
      modalTitle,
      modalMessage,
      redirectToHome,
      redirectToStep,
      stepToRedirect,
      enderecoDivulga,
      loading,
      fileLoading,
    } = this.state;
    const { step1_newValue, valorAnalise, valorAnaliseHalf } = this.props;

    let notValidClasses = {};

    if (
      typeof this.state.emailRespCertVal == 'undefined' ||
      this.state.emailRespCertVal
    ) {
      notValidClasses.emailRespCertCls = 'no-error col-md-8';
    } else {
      notValidClasses.emailRespCertCls = 'has-error col-md-8';
      notValidClasses.emailRespCertValGrpCls = 'val-err-tooltip';
    }

    if (
      typeof this.state.emailDivulgacaoVal == 'undefined' ||
      this.state.emailDivulgacaoVal
    ) {
      notValidClasses.emailDivulgacaoCls = 'no-error col-md-8';
    } else {
      notValidClasses.emailDivulgacaoCls = 'has-error col-md-8';
      notValidClasses.emailDivulgacaoValGrpCls = 'val-err-tooltip';
    }
    return (
      <div className="step step2">
        <div className="row">
          <form>
            <div className="form-check">
              <RadioInput
                className="form-check-input"
                type="checkbox"
                checked={!resource.aceite}
                onChange={() => this.handleCheckClick()}
                id="defaultCheck1"
              />
              <SmallLabel className="form-check-label" htmlFor="defaultCheck1">
                Concordo com a cobrança de R$
                {valorAnaliseHalf ? valorAnalise / 2 : valorAnalise} para a taxa
                de análise inicial da empresa. Estou ciente que, caso a empresa
                não seja aprovada, <strong>NÃO HAVERÁ O RESSARCIMENTO</strong>{' '}
                do valor, e, caso seja aprovada, será necessária a contratação
                de um dos planos disponibilizados em nosso site para a
                utilização do selo.
              </SmallLabel>

              <Label htmlFor="validationCustom02">Nome da Empresa</Label>
              <input
                type="text"
                className="form-control"
                id="validationCustom02"
                value={step1_newValue.nomeEmpresa}
                onBlur={this.validationCheck}
                disabled
              />
              <Label htmlFor="validationCustom02">CNPJ</Label>
              <input
                type="text"
                className="form-control"
                id="validationCustom02"
                value={step1_newValue.cnpj}
                disabled
              />

              <StyledP>RESPOSÁVEL PELA CERTIFICAÇÃO</StyledP>
              <Label htmlFor="validationCustom02">Nome*</Label>
              <input
                type="text"
                className="form-control"
                id="validationCustom02"
                value={resource.respCert}
                onChange={event =>
                  this.handleChange('respCert', event.target.value)
                }
                //onBlur={this.validationCheck}
                required
              />

              <Label htmlFor="validationCustom02">Email*</Label>
              <input
                type="text"
                className="form-control"
                id="validationCustom02"
                value={resource.emailRespCert}
                onChange={event =>
                  this.handleChange('emailRespCert', event.target.value)
                }
                onBlur={this.validationCheck}
                required
              />
              <div className={notValidClasses.emailRespCertValGrpCls}>
                {this.state.emailRespCertValMsg}
              </div>

              <Label htmlFor="validationCustom02">Telefone*</Label>
              <InputMask
                className="form-control"
                mask="(99) 99999-9999"
                maskChar={null}
                alwaysShowMask={false}
                value={resource.telRespCert}
                onChange={event =>
                  this.handleChange('telRespCert', event.target.value)
                }
                beforeMaskedValueChange={this.beforeMaskedValueChange}
              />

              <hr />
              <StyledP className="mb-0">QUADRO DE SÓCIOS</StyledP>
              <small>Por favor, preencha pelo menos 1 sócio</small>

              <div className="d-inline-flex w-100 mt-2">
                <div className="col-md-1">
                  <StyledButton
                    className="btn btn-secondary"
                    onClick={event => this.addItem(event)}
                  >
                    +
                  </StyledButton>

                  <StyledButtonForColSm
                    className="btn btn-secondary m-0"
                    onClick={event => this.addItem(event)}
                  >
                    Adicionar
                  </StyledButtonForColSm>
                </div>

                <StyledDiv className="col-md-5 text-center">
                  <label
                    htmlFor="colFormLabelSm"
                    className="col-form-label col-form-label-sm ml-1 mr-1"
                  >
                    Nome do Sócio
                  </label>
                </StyledDiv>

                <StyledDiv className="col-md-5 text-center">
                  <label
                    htmlFor="colFormLabelSm"
                    className="col-form-label col-form-label-sm ml-1 mr-1 pr-5"
                  >
                    CPF do sócio
                  </label>
                </StyledDiv>
              </div>
              <div className="d-inline-flex w-100">
                <StyledCol className="col-md-1 p-0">
                  {this.state.items.map((item, index) => (
                    <Styledh4 key={item.id}>{index + 1}</Styledh4>
                  ))}
                </StyledCol>
                <div className="col-md-11">
                  <DragDropContext onDragEnd={this.onDragEnd}>
                    <Droppable droppableId="droppable">
                      {(provided, snapshot) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={getListStyle(snapshot.isDraggingOver)}
                        >
                          {this.state.items.map((item, index) => (
                            <Draggable
                              key={item.id}
                              draggableId={item.id}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style
                                  )}
                                >
                                  <div className="form-group row w-100">
                                    <DynamicLabel
                                      htmlFor="colFormLabelSm"
                                      className="col-form-label col-form-label-sm  ml-1 mr-1"
                                    >
                                      Nome do sócio
                                    </DynamicLabel>
                                    <div className="col-md-5">
                                      <input
                                        type="email"
                                        className="form-control form-control-sm"
                                        id="colFormLabelSm"
                                        value={
                                          resource[`socios_nome_${item.id}`] ||
                                          ''
                                        }
                                        onChange={event =>
                                          this.handleChange(
                                            `socios_nome_${item.id}`,
                                            event.target.value
                                          )
                                        }
                                      />
                                    </div>
                                    <DynamicLabel
                                      htmlFor="colFormLabelSm"
                                      className="col-form-label col-form-label-sm  ml-1 mr-1"
                                    >
                                      CPF do sócio
                                    </DynamicLabel>
                                    <div className="col-md-5">
                                      <InputMask
                                        className="form-control form-control-sm"
                                        mask="999.999.999-99"
                                        maskChar={null}
                                        alwaysShowMask={false}
                                        value={
                                          resource[`socios_cpf_${item.id}`] ||
                                          ''
                                        }
                                        onChange={event =>
                                          this.handleChange(
                                            `socios_cpf_${item.id}`,
                                            event.target.value
                                          )
                                        }
                                      />
                                      {/* <input type="email" className="form-control form-control-sm" 
                                        id="colFormLabelSm" 
                                        value={resource[`socios_cpf_${item.id}`] ||  ""}
                                        onChange={event => this.handleChange(`socios_cpf_${item.id}`,event.target.value)}/> */}
                                    </div>
                                    <div className="col-md-1">
                                      <StyledButton
                                        className="btn btn-secondary"
                                        onClick={() => this.removeItem(index)}
                                        disabled={items.length === 1}
                                      >
                                        -
                                      </StyledButton>
                                      <StyledButtonForColSm
                                        className="btn btn-secondary"
                                        onClick={() => this.removeItem(index)}
                                        disabled={items.length === 1}
                                      >
                                        Excluir
                                      </StyledButtonForColSm>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
              </div>

              {step1_newValue.segmento.value === '6' && (
                <div className="file-input">
                  <hr />

                  <div className="form-inline">
                    <div className="form-group">
                      <label htmlFor="inputPassword6">
                        Alvará Vigilância 1
                      </label>
                      <input
                        type="file"
                        id="inputPassword6"
                        className="form-control mx-sm-3"
                        aria-describedby="passwordHelpInline"
                        onChange={event => this.saveFile(event, 1)}
                      />
                      {fileLoading && (
                        <div className="text-center">
                          <PulseLoader
                            sizeUnit={'px'}
                            size={12}
                            color={'#123abc'}
                            loading={fileLoading}
                          />
                          <small>Salvando arquivo...</small>
                        </div>
                      )}
                    </div>

                    <div className="form-group">
                      <label htmlFor="inputPassword6">
                        Alvará Vigilância 2
                      </label>
                      <input
                        type="file"
                        id="inputPassword6"
                        className="form-control mx-sm-3"
                        aria-describedby="passwordHelpInline"
                        onChange={event => this.saveFile(event, 2)}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="inputPassword6">
                        Alvará Vigilância 3
                      </label>
                      <input
                        type="file"
                        id="inputPassword6"
                        className="form-control mx-sm-3"
                        aria-describedby="passwordHelpInline"
                        onChange={event => this.saveFile(event, 3)}
                      />
                    </div>
                  </div>
                </div>
              )}

              {step1_newValue.segmento.value === '68' && (
                <div className="file-input">
                  <hr />

                  <div className="form-inline">
                    <div className="form-group">
                      <label htmlFor="inputPassword6">Alvará AVCB</label>
                      <input
                        type="file"
                        id="inputPassword6"
                        className="form-control mx-sm-3"
                        aria-describedby="passwordHelpInline"
                        onChange={event => this.saveFile(event, 1)}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="inputPassword6">
                        IPTU ou Contrato de Locação
                      </label>
                      <input
                        type="file"
                        id="inputPassword6"
                        className="form-control mx-sm-3"
                        aria-describedby="passwordHelpInline"
                        onChange={event => this.saveFile(event, 2)}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="inputPassword6">
                        Alvará de Funcionamento
                      </label>
                      <input
                        type="file"
                        id="inputPassword6"
                        className="form-control mx-sm-3"
                        aria-describedby="passwordHelpInline"
                        onChange={event => this.saveFile(event, 3)}
                      />
                    </div>
                  </div>
                </div>
              )}

              {step1_newValue.segmento.value === '18' ||
                (step1_newValue.segmento.value === '29' && (
                  <div className="file-input">
                    <hr />

                    <div className="form-inline">
                      <div className="form-group">
                        <label htmlFor="inputPassword6">
                          Seguro dos Equipamentos
                        </label>
                        <input
                          type="file"
                          id="inputPassword6"
                          className="form-control mx-sm-3"
                          aria-describedby="passwordHelpInline"
                          onChange={event => this.saveFile(event, 1)}
                        />
                      </div>
                    </div>
                  </div>
                ))}

              <div className="dados-divulgacao-empresa">
                <hr />
                <StyledP>DADOS PARA DIVULGAÇÃO DA EMPRESA</StyledP>
                <div className="form-row">
                  <div className="col-md-6 mb-3">
                    <Label htmlFor="validationCustom01">Nome Divulgação*</Label>
                    <input
                      className="form-control"
                      value={resource.nomeFantasia.toUpperCase()}
                      onChange={event =>
                        this.handleChange('nomeFantasia', event.target.value)
                      }
                      // onBlur={this.cnpjReceita}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <Label htmlFor="validationCustom02">Site*</Label>
                    <input
                      type="text"
                      className="form-control"
                      id="validationCustom02"
                      value={resource.site}
                      onChange={event =>
                        this.handleChange('site', event.target.value)
                      }
                      // onBlur={this.validationCheck}
                      required
                    />
                  </div>
                </div>

                <div className="form-row">
                  <div className="col-md-6 mb-3">
                    <Label htmlFor="validationCustom01">
                      E-mail para divulgação caso aprovado*
                    </Label>
                    <input
                      className="form-control"
                      value={resource.emailDivulgacao}
                      onChange={event =>
                        this.handleChange('emailDivulgacao', event.target.value)
                      }
                      onBlur={this.validationCheck}
                    />
                    <div className={notValidClasses.emailDivulgacaoValGrpCls}>
                      {this.state.emailDivulgacaoValMsg}
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <Label htmlFor="validationCustom02">
                      Telefone para contato caso aprovado*
                    </Label>
                    <InputMask
                      className="form-control"
                      mask="(99) 99999-9999"
                      maskChar={null}
                      alwaysShowMask={false}
                      value={resource.telefoneDivulgacao}
                      onChange={event =>
                        this.handleChange(
                          'telefoneDivulgacao',
                          event.target.value
                        )
                      }
                      beforeMaskedValueChange={this.beforeMaskedValueChange}
                    />
                  </div>
                </div>
              </div>

              <div className="endereco-receita">
                <hr />
                <StyledP>ENDEREÇO RECEITA</StyledP>

                <div className="form-row">
                  <div className="col-md-4 mb-3">
                    <Label htmlFor="validationCustom01">CEP*</Label>
                    <input
                      className="form-control"
                      value={resource.cep}
                      readOnly
                    />
                  </div>
                  <div className="col-md-4 mb-3">
                    <Label htmlFor="validationCustom02">Logradouro*</Label>
                    <input
                      type="text"
                      className="form-control"
                      id="validationCustom02"
                      value={resource.logradouro}
                      readOnly
                    />
                  </div>
                  <div className="col-md-4 mb-3">
                    <Label htmlFor="validationCustom02">Número*</Label>
                    <input
                      type="text"
                      className="form-control"
                      id="validationCustom02"
                      value={resource.numero}
                      readOnly
                    />
                  </div>
                </div>

                <div className="form-row">
                  <div className="col-md-4 mb-3">
                    <Label htmlFor="validationCustom01">Complemento</Label>
                    <input
                      className="form-control"
                      value={resource.complemento}
                      readOnly
                    />
                  </div>
                  <div className="col-md-4 mb-3">
                    <Label htmlFor="validationCustom02">Cidade*</Label>
                    <input
                      type="text"
                      className="form-control"
                      id="validationCustom02"
                      value={resource.cidade}
                      readOnly
                    />
                  </div>
                  <div className="col-md-4 mb-3">
                    <Label htmlFor="validationCustom02">UF*</Label>
                    <input
                      type="text"
                      className="form-control"
                      id="validationCustom02"
                      value={resource.uf}
                      readOnly
                    />
                  </div>
                </div>
              </div>

              <div className="button-add-remove-address">
                {!addDifferentAddress && (
                  <ButtonAddRemoveAddress
                    className="btn btn-secondary"
                    onClick={event => {
                      event.preventDefault();
                      this.setState({
                        addDifferentAddress: true,
                        showEnderecoDivulga: true,
                        resource: {
                          ...resource,
                          enderecosIguais: 0,
                        },
                      });
                    }}
                  >
                    <strong>+</strong> Adicionar endereço de divulgação
                    diferente da Receita Federal
                  </ButtonAddRemoveAddress>
                )}

                {addDifferentAddress && (
                  <ButtonAddRemoveAddress
                    className="btn btn-secondary"
                    onClick={event => {
                      event.preventDefault();
                      this.setState({
                        addDifferentAddress: false,
                        showEnderecoDivulga: false,
                        resource: {
                          ...resource,
                          enderecosIguais: 1,
                        },
                      });
                    }}
                  >
                    <strong>-</strong> Excluir endereço de divulgação diferente
                    da Receita Federal
                  </ButtonAddRemoveAddress>
                )}
              </div>

              {showEnderecoDivulga && (
                <div className="endereco-divulga">
                  <hr />
                  <StyledP>ENDEREÇO DIVULGAÇÃO</StyledP>

                  <div className="form-row">
                    <div className="col-md-4 mb-3">
                      <Label htmlFor="validationCustom01">CEP*</Label>
                      <input
                        className="form-control"
                        value={enderecoDivulga.cep1}
                        onChange={event =>
                          this.handleChangeEnderecoDivulga(
                            'cep1',
                            event.target.value
                          )
                        }
                      />
                    </div>
                    <div className="col-md-4 mb-3">
                      <Label htmlFor="validationCustom02">Logradouro*</Label>
                      <input
                        type="text"
                        className="form-control"
                        id="validationCustom02"
                        value={enderecoDivulga.logradouro1}
                        onChange={event =>
                          this.handleChangeEnderecoDivulga(
                            'logradouro1',
                            event.target.value
                          )
                        }
                        required
                      />
                    </div>
                    <div className="col-md-4 mb-3">
                      <Label htmlFor="validationCustom02">Número*</Label>
                      <input
                        type="text"
                        className="form-control"
                        id="validationCustom02"
                        value={enderecoDivulga.numero1}
                        onChange={event =>
                          this.handleChangeEnderecoDivulga(
                            'numero1',
                            event.target.value
                          )
                        }
                        required
                      />
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="col-md-4 mb-3">
                      <Label htmlFor="validationCustom01">Complemento</Label>
                      <input
                        className="form-control"
                        value={enderecoDivulga.complemento1}
                        onChange={event =>
                          this.handleChangeEnderecoDivulga(
                            'complemento1',
                            event.target.value
                          )
                        }
                      />
                    </div>
                    <div className="col-md-4 mb-3">
                      <Label htmlFor="validationCustom02">Cidade*</Label>
                      <input
                        type="text"
                        className="form-control"
                        id="validationCustom02"
                        value={enderecoDivulga.cidade1}
                        onChange={event =>
                          this.handleChangeEnderecoDivulga(
                            'cidade1',
                            event.target.value
                          )
                        }
                        required
                      />
                    </div>
                    <div className="col-md-4 mb-3">
                      <Label htmlFor="validationCustom02">UF*</Label>
                      <input
                        type="text"
                        className="form-control"
                        id="validationCustom02"
                        value={enderecoDivulga.uf1}
                        onChange={event =>
                          this.handleChangeEnderecoDivulga(
                            'uf1',
                            event.target.value
                          )
                        }
                        required
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </form>
        </div>
        <Modal
          {...this.props}
          show={showModal}
          onHide={() => this.setState({ showModal: false })}
          title={modalTitle}
          body={modalMessage}
          showSaveButton={false}
          redirectToHome={redirectToHome}
          redirectToStep={redirectToStep}
          stepToRedirect={stepToRedirect}
        />
        {loading && (
          <div className="text-center mt-4">
            <ClipLoader
              sizeUnit={'px'}
              size={30}
              color={'#123abc'}
              loading={loading}
            />
            <p>Carregando...</p>
          </div>
        )}
      </div>
    );
  }
}

export default Step2;
