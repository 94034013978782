import React, { Component } from "react";
import styled from 'styled-components';
import Axios from 'axios';
import Modal from '../../components/Modal/Modal'
import ClipLoader from 'react-spinners/ClipLoader';


const Label = styled.label`
  font-size: 0.7rem;
  font-weight:500;
`;

export default class Step3 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      modalMessage: "",
      modalTitle: "",
      redirectToHome:false,
      redirectToStep:false,
      stepToRedirect: null,
      loading:false,
      valorAnalise:null
    };

    /**
     * Loads from API all available values
     */
    this.loadValorAnalise = () => {
      // eslint-disable-next-line no-undef
      Axios.get(`${process.env.GATSBY_API_URL_PROD}Config/retornarJSON/valorAnalise/`).then(response => {
        this.setState({
          valorAnalise: response.data[0].valConfig
        });
      });
    };


    this.solicitarBoleto = async () => {
      this.setState({loading:true})

      let formData = new FormData();  
      formData.append('codProcesso', this.props.codProcesso);
      formData.append('codEmpresa', this.props.codEmpresa);

      const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          'Accept':'*/*'
        }
      }
  
       // eslint-disable-next-line no-undef
       const response = await Axios.post(`${process.env.GATSBY_API_URL}Processo/solicitarBoleto/`, formData, config);
       
       if(response.data === 1) {
        this.setState({
          modalTitle:"ATENÇÃO",
          modalMessage: "Boleto solicitado. Você receberá um email em até 2 dias uteis com as informações de pagamento.",
          showModal: true,
          redirectToHome:true
        })
        this.setState({loading:false})
        return false;
       }

       this.setState({
        modalTitle:"ATENÇÃO",
        modalMessage: "Erro ao solicitar boleto. Tente novamente mais tarde.",
        showModal: true
      })
      this.setState({loading:false})
      return false;
    };

  }

  componentDidMount() {
    this.loadValorAnalise();
  }

  componentWillUnmount() {}


  render() {
    const {step1_newValue} = this.props;
    const {showModal, modalTitle, modalMessage, redirectToHome, redirectToStep, stepToRedirect, loading, valorAnalise} = this.state;

    return (
      <div className="step step3">
         <h4>Pagamento</h4>
         <hr></hr>
        <div className="row">
          <Label htmlFor="validationCustom02">Nome da Empresa</Label>
            <input type="text" 
                  className="form-control" 
                  id="validationCustom02" 
                  value={step1_newValue.nomeEmpresa}
                  onBlur={this.validationCheck}
                  disabled/>
          <Label htmlFor="validationCustom02">CNPJ</Label>
            <input type="text" 
                  className="form-control" 
                  id="validationCustom02" 
                  value={step1_newValue.cnpj}
                  disabled/>
          <p className="mt-4">
          Atenção: A taxa inicial de R${valorAnalise} não é ressarcida! Caso aprovado, você deve contratar um dos planos para utilizar o selo em suas mídias. Clique no botão abaixo para realizar o pagamento.
          </p>
          <button className="btn btn-secondary"
          onClick={() => {this.solicitarBoleto()}}>Solicitar Boleto</button>
        </div>
        <Modal {...this.props}
          show={showModal}
          onHide={() => this.setState({showModal:false})}
          title={modalTitle}
          body={modalMessage}
          showSaveButton={false}
          redirectToHome={redirectToHome}
          redirectToStep={redirectToStep}
          stepToRedirect={stepToRedirect}/>
          {loading && (
            <div className="text-center">
                <ClipLoader
                sizeUnit={"px"}
                size={30}
                color={'#123abc'}
                loading={loading}
                />
                <p>Carregando...</p>
           
            </div>
        )}
      </div>
    )
  }
}